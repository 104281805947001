import React from 'react';

const RamadanOverlay = ({ isDark }) => {
    // Simplified colors object focusing on elements only
    const colors = {
        itemColor: isDark ? '#ffd54f' : '#1a237e',
        opacity: isDark ? '0.95' : '0.6',
        glowColor: isDark
            ? 'rgba(255, 213, 79, 0.7)'
            : 'rgba(26, 35, 126, 0.5)',
    };

    // Create dynamic stars
    const stars = Array.from({ length: 50 }, (_, i) => ({
        id: i,
        top: Math.random() * 100,
        left: Math.random() * 100,
        delay: Math.random() * 3,
        size: Math.random() * 2 + 1
    }));

    const ropePoints = 4;
    const ropeLanterns = Array.from({ length: ropePoints }, (_, i) => ({
        id: i,
        offset: i < 2 ? (20 * i) : (70 + (20 * (i - 2))),
        delay: Math.random() * 2,
        size: Math.random() * 0.2 + 0.8
    }));

    return (
        <div className="absolute inset-0 w-full h-full pointer-events-none">
            {/* Rope decoration */}
            <div className="absolute top-0 inset-x-0 h-32">
                {/* The rope */}
                <div
                    className="absolute top-1 inset-x-0 h-0.5"
                    style={{
                        background: `linear-gradient(to right, transparent, ${colors.itemColor}, transparent)`,
                        opacity: colors.opacity,
                        filter: `drop-shadow(0 0 2px ${colors.glowColor})`
                    }}
                />

                {/* Hanging lanterns */}
                {ropeLanterns.map((lantern) => (
                    <div
                        key={lantern.id}
                        className="absolute top-1"
                        style={{
                            left: `${lantern.offset}%`,
                            transform: `scale(${lantern.size})`,
                            animation: `swing ${2 + lantern.delay}s ease-in-out infinite`,
                            transformOrigin: 'top center'
                        }}
                    >
                        {/* Rope piece */}
                        <div
                            className="w-px h-8 mx-auto"
                            style={{
                                background: `linear-gradient(to bottom, ${colors.itemColor}, transparent)`,
                                opacity: colors.opacity,
                                filter: `drop-shadow(0 0 1px ${colors.glowColor})`
                            }}
                        />

                        {/* Lantern */}
                        <div className="relative -mt-1">
                            <svg
                                viewBox="0 0 24 24"
                                className="w-6 h-8"
                                style={{ filter: `drop-shadow(0 0 3px ${colors.glowColor})` }}
                            >
                                <path
                                    fill="currentColor"
                                    className="text-[#ffd54f]"
                                    style={{ opacity: colors.opacity }}
                                    d="M8,2H16L15,7H9L8,2M16,8V16C16,17.11 15.11,18 14,18H10C8.89,18 8,17.11 8,16V8H16M7,22H17V20H7V22Z"
                                />
                            </svg>
                            {/* Lantern glow */}
                            <div
                                className="absolute inset-0 animate-glow"
                                style={{
                                    background: `radial-gradient(circle at center, ${colors.itemColor} 0%, transparent 70%)`,
                                    transform: 'scale(1.5)',
                                    mixBlendMode: 'screen'
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>

            {/* Main moon */}
            <div className="absolute top-16 right-8 w-16 h-16 animate-pulse" style={{ mixBlendMode: 'screen' }}>
                <svg
                    viewBox="0 0 24 24"
                    className="w-full h-full"
                    style={{ filter: `drop-shadow(0 0 8px ${colors.glowColor})` }}
                >
                    <path
                        fill={colors.itemColor}
                        d="M12 2A9.91 9.91 0 0 0 9 2.46A10 10 0 0 1 9 21.54A10 10 0 1 0 12 2Z"
                        style={{ opacity: colors.opacity }}
                    />
                </svg>
            </div>

            {/* Stars */}
            {stars.map(star => (
                <div
                    key={star.id}
                    className="absolute rounded-full animate-twinkle"
                    style={{
                        top: `${star.top}%`,
                        left: `${star.left}%`,
                        width: `${star.size}px`,
                        height: `${star.size}px`,
                        backgroundColor: colors.itemColor,
                        animationDelay: `${star.delay}s`,
                        filter: `blur(0.5px) drop-shadow(0 0 2px ${colors.glowColor})`,
                        opacity: colors.opacity
                    }}
                />
            ))}

            {/* Additional floating lanterns */}
            {[1, 2, 3].map((i) => (
                <div
                    key={i}
                    className="absolute"
                    style={{
                        top: `${20 + i * 15}%`,
                        left: `${5 + i * 8}%`,
                        animation: `float-${i} ${3 + i}s ease-in-out infinite`,
                        mixBlendMode: 'screen'
                    }}
                >
                    <svg
                        viewBox="0 0 24 24"
                        className="w-8 h-12"
                        style={{ filter: `drop-shadow(0 0 5px ${colors.glowColor})` }}
                    >
                        <path
                            fill={colors.itemColor}
                            d="M8,2H16L15,7H9L8,2M16,8V16C16,17.11 15.11,18 14,18H10C8.89,18 8,17.11 8,16V8H16M7,22H17V20H7V22Z"
                            style={{ opacity: colors.opacity }}
                        />
                    </svg>
                </div>
            ))}

            {/* Decorative borders */}
            <div className="absolute top-0 inset-x-0">
                <div
                    className="h-1"
                    style={{
                        background: `linear-gradient(to right, transparent, ${colors.itemColor}, transparent)`,
                        opacity: colors.opacity
                    }}
                />
                <div
                    className="h-px translate-y-2"
                    style={{
                        background: `linear-gradient(to right, transparent, ${colors.itemColor}, transparent)`,
                        opacity: colors.opacity
                    }}
                />
            </div>

            {/* <div className="absolute bottom-0 inset-x-0">
                <div
                    className="h-px -translate-y-2"
                    style={{
                        background: `linear-gradient(to right, transparent, ${colors.itemColor}, transparent)`,
                        opacity: colors.opacity
                    }}
                />
                <div
                    className="h-1"
                    style={{
                        background: `linear-gradient(to right, transparent, ${colors.itemColor}, transparent)`,
                        opacity: colors.opacity
                    }}
                />
            </div> */}

            {/* Corner decorations */}
            <div className="absolute -top-2.5 -left-2.5 w-24 h-24" style={{ opacity: 0.9, mixBlendMode: 'normal' }}>
                <svg viewBox="0 0 100 100" className="w-full h-full" style={{ color: colors.itemColor }}>
                    <path fill="currentColor" d="M0,0 L40,0 A40,40 0 0,1 0,40 Z" />
                    <path fill="currentColor" d="M15,0 L30,0 A30,30 0 0,1 0,30 L0,15 A15,15 0 0,0 15,0" fillOpacity="0.7" />
                </svg>
            </div>
            <div className="absolute -top-2.5 -right-2.5 w-24 h-24 rotate-90" style={{ opacity: 0.9, mixBlendMode: 'normal' }}>
                <svg viewBox="0 0 100 100" className="w-full h-full" style={{ color: colors.itemColor }}>
                    <path fill="currentColor" d="M0,0 L40,0 A40,40 0 0,1 0,40 Z" />
                    <path fill="currentColor" d="M15,0 L30,0 A30,30 0 0,1 0,30 L0,15 A15,15 0 0,0 15,0" fillOpacity="0.7" />
                </svg>
            </div>
        </div>
    );
};

// Enhanced animations
const style = document.createElement('style');
style.textContent = `
  @keyframes twinkle {
    0%, 100% { opacity: 0.6; transform: scale(1); }
    50% { opacity: 1; transform: scale(1.5); }
  }

  @keyframes swing {
    0%, 100% { transform: rotate(-3deg); }
    50% { transform: rotate(3deg); }
  }

  @keyframes glow {
    0%, 100% { opacity: 0.3; }
    50% { opacity: 0.6; }
  }

  @keyframes float-1 {
    0%, 100% { transform: translate(0, 0); }
    50% { transform: translate(5px, -10px); }
  }

  @keyframes float-2 {
    0%, 100% { transform: translate(0, 0); }
    50% { transform: translate(-5px, -15px); }
  }

  @keyframes float-3 {
    0%, 100% { transform: translate(0, 0); }
    50% { transform: translate(8px, -12px); }
  }

  .animate-twinkle {
    animation: twinkle 2s ease-in-out infinite;
  }

  .animate-glow {
    animation: glow 2s ease-in-out infinite;
  }

  .animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
`;
document.head.appendChild(style);

export default RamadanOverlay;