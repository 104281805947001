import React, { useState } from 'react';
import { Eye, X } from 'lucide-react';
import { useQuery } from '@apollo/client';
import { GET_CATALOG_BY_NAME } from '../graphql/queries';
import CatalogPreview from './CatalogPreview';
import LoadingSpinner from './LoadingSpinner';

const PreviewInOverview = ({ catalogName }) => {
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    const { loading, error, data } = useQuery(GET_CATALOG_BY_NAME, {
        variables: {
            catalogName,
            days: 7,
            limit: 5
        },
        skip: !catalogName,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const handleOpenPreview = () => setIsPreviewOpen(true);
    const handleClosePreview = () => setIsPreviewOpen(false);

    if (loading) return <LoadingSpinner />;
    if (error) return <p>Error: {error.message}</p>;

    const { catalog, categoriesWithItems, uncategorizedItems } = data?.getCatalogByName || {};

    return (
        <>
            <button
                onClick={handleOpenPreview}
                className="w-full bg-[#191970] text-white rounded-md py-2 px-4 flex items-center justify-center hover:bg-blue-700 transition-colors duration-300 lg:hidden"
            >
                <Eye className="h-5 w-5 mr-2" />
                Preview Catalog
            </button>

            {isPreviewOpen && (
                <div className="fixed inset-0 bg-white z-50 overflow-auto">
                    <div className="sticky top-0 bg-white p-4 flex justify-between items-center border-b">
                        <h2 className="text-xl font-bold">Catalog Preview</h2>
                        <button onClick={handleClosePreview} className="text-gray-600 bg-gray-100">
                            <X size={24} />
                        </button>
                    </div>
                    <div className="p-4">
                        <CatalogPreview
                            businessInfo={{
                                name: catalog.name,
                                description: catalog.description,
                                logo: catalog.logo,
                                phone: catalog.phone,
                                socialMedia: catalog.socialMedia,
                                festiveMode: catalog.festiveMode || false,
                            }}
                            categories={categoriesWithItems}
                            uncategorizedItems={uncategorizedItems}
                            themeName={catalog.theme}
                            viewMode={catalog.viewMode}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default PreviewInOverview;