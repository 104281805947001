// hooks/useCatalogMutations.js
import { useMutation, useApolloClient } from '@apollo/client';
import { UPDATE_CATALOG, CATALOG_FIELDS } from '../graphql/mutations';

// Default values for catalog fields to prevent undefined errors
const DEFAULT_CATALOG = {
    name: '',
    description: '',
    logo: null,
    phone: '',
    socialMedia: {
        facebook: '',
        instagram: '',
    },
    theme: 'Classic',
    viewMode: 'grid',
    allowCheckout: false,
    businessType: null,
    businessCategory: null,
    location: null,
    setupCompleted: false,
    festiveMode: false,
};

const generateOptimisticResponse = (currentData = {}, newData = {}) => {
    // Merge defaults with current data and new data
    const data = {
        ...DEFAULT_CATALOG,
        ...currentData,
        ...newData,
    };

    return {
        __typename: 'Mutation',
        updateCatalog: {
            __typename: 'Catalog',
            id: data.id,
            name: data.name,
            description: data.description,
            logo: data.logo,
            phone: data.phone,
            socialMedia: {
                __typename: 'SocialMedia',
                facebook: data.socialMedia?.facebook || '',
                instagram: data.socialMedia?.instagram || '',
            },
            theme: data.theme,
            viewMode: data.viewMode,
            allowCheckout: data.allowCheckout,
            businessType: data.businessType,
            businessCategory: data.businessCategory,
            location: data.location,
            setupCompleted: data.setupCompleted,
            festiveMode: data.festiveMode,
        }
    };
};

export const useCatalogMutations = () => {
    const [updateCatalog] = useMutation(UPDATE_CATALOG);
    const client = useApolloClient();

    const optimizedUpdate = async (variables, currentData = {}) => {
        if (!variables.id) {
            throw new Error('Catalog ID is required');
        }

        try {
            // Get existing data from cache if available
            const existingData = client.readFragment({
                id: `Catalog:${variables.id}`,
                fragment: CATALOG_FIELDS,
            }) || {};

            // Merge existing data with current data
            const mergedCurrentData = {
                ...DEFAULT_CATALOG,
                ...existingData,
                ...currentData,
            };

            const result = await updateCatalog({
                variables,
                optimisticResponse: generateOptimisticResponse(mergedCurrentData, variables),
                update: (cache, { data }) => {
                    if (data?.updateCatalog) {
                        cache.modify({
                            id: cache.identify(data.updateCatalog),
                            fields: {
                                name: () => data.updateCatalog.name,
                                description: () => data.updateCatalog.description,
                                logo: () => data.updateCatalog.logo,
                                phone: () => data.updateCatalog.phone,
                                socialMedia: () => data.updateCatalog.socialMedia,
                                theme: () => data.updateCatalog.theme,
                                viewMode: () => data.updateCatalog.viewMode,
                                allowCheckout: () => data.updateCatalog.allowCheckout,
                                businessType: () => data.updateCatalog.businessType,
                                businessCategory: () => data.updateCatalog.businessCategory,
                                location: () => data.updateCatalog.location,
                                setupCompleted: () => data.updateCatalog.setupCompleted,
                                festiveMode: () => data.updateCatalog.festiveMode,
                            },
                        });
                    }
                },
            });

            return result;
        } catch (error) {
            console.error('Mutation error:', error);
            throw error;
        }
    };

    return { optimizedUpdate };
}