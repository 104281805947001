const themes = {
    Classic: {
        colors: {
            background: 'bg-white',
            text: 'text-gray-700',
        },
        fonts: {
            main: 'font-sans',
            heading: 'font-serif text-gray-900',
            priColor: 'text-gray-900',
            secColor: 'text-gray-900',  //item name and description with 70% opacity
        },
        layout: {
            header: 'p-4 bg-white border-b border-gray-200',
            footer: 'bg-gray-100 text-gray-800 text-xs ',
            itemCard: 'bg-white p-2 shadow-md hover:shadow-md transition-shadow duration-300 rounded-br-2xl overflow-hidden',
            itemPrice: 'flex justify-end font-bold text-sm text-gray-900',
            cartadd: 'bg-gray-900 text-gray-100 rounded-lg',
            cart: 'bg-gray-900 text-gray-100 rounded-lg',
            categoriesOverview: 'bg-gray-100 text-gray-900',
            categoriesOverviewActive: 'bg-gray-900 text-gray-100',
            categoriesOverviewButtons: 'px-3 py-2 text-sm transition-colors duration-200 bg-gray-200 hover:bg-gray-400 mx-1 rounded-full',
            images: 'rounded-md',
            grid: 'grid grid-cols-2 gap-4',
            list: 'space-y-4 divide-y divide-gray-200',
            detailpage: "bg-white"
        },
        detailPage: {
            main: "bg-gray-100 text-gray-900",
            descripButton: "bg-gray-900 text-gray-100",
            description: "text-gray-600",
            closeButton: "text-gray-900",
        },
        watermark: {
            color: ''
        },
        isDark: false

    },

    Modern: {
        colors: {
            background: 'bg-gradient-to-br from-gray-700 to-gray-800',
            text: 'text-gray-200',
        },
        fonts: {
            main: 'font-nunito',
            heading: 'font-nunito text-white ',
            priColor: 'text-white',
            secColor: 'text-white', //item name and description with 70% opacity
        },
        layout: {
            header: 'shadow-md p-4 bg-gray-800',
            footer: 'bg-gray-800 text-gray-200',
            itemCard: 'bg-gray-800 shadow-xl rounded-xl p-2',
            itemPrice: 'text-white flex justify-end text-sm font-bold',
            cartadd: 'bg-white text-gray-800 rounded-lg',
            cart: 'bg-white text-gray-800 rounded-lg',
            categoriesOverview: 'bg-gray-800 text-white',
            categoriesOverviewActive: 'bg-white text-gray-800',
            categoriesOverviewButtons: 'bg-gray-800 whitespace-nowrap px-3 py-1 text-sm rounded-md transition-colors duration-200',
            images: 'rounded-md',
            grid: 'grid grid-cols-2 gap-4',
            list: 'space-y-4',
            detailpage: "bg-gray-800 text-white"
        },
        detailPage: {
            main: "bg-gray-800 text-white",
            descripButton: "bg-gray-100 text-gray-800",
            description: "text-gray-400",
            closeButton: "text-gray-200",
        },
        watermark: {
            color: 'brightness-0 invert'
        },
        isDark: true
    },

    Blueberry: {
        colors: {
            background: 'bg-[#cce6fc]',
            text: 'text-blue-800',
        },
        fonts: {
            main: 'font-sans',
            heading: 'font-serif text-[#191970]',
            priColor: 'text-[#191970]',
            secColor: 'text-blue-800',  //item description
        },
        layout: {
            header: 'p-4 bg-[#cce6fc] border-b border-gray-200',
            footer: 'bg-[#cce6fc] text-[#191970] text-xs text-center',
            itemCard: 'bg-white p-2 hover:shadow-md transition-shadow duration-300 rounded-[1.8rem] overflow-hidden',
            itemPrice: 'flex justify-end pr-2 font-bold text-sm text-[#191970]',
            cartadd: 'bg-[#FF7C45] text-[#191970] rounded-full',
            cart: 'bg-[#FF7C45] text-[#191970] rounded-full',
            categoriesOverview: 'bg-[#cce6fc] text-[#191970]',
            categoriesOverviewActive: 'bg-[#FF7C45] ',
            categoriesOverviewButtons: 'px-3 py-2 text-sm transition-colors duration-200 bg-white hover:opacity-80 mx-1 rounded-full',
            images: 'rounded-[1.7rem]',
            grid: 'grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5',
            list: 'space-y-2 divide-y',
            detailpage: "bg-[#cce6fc]"
        },
        detailPage: {
            main: "bg-[#cce6fc] text-[#191970]",
            descripButton: "bg-[#191970] text-[#cce6fc]",
            description: "text-[#191970] opacity-70",
            closeButton: "text-[#191970]",
        },

        watermark: {
            color: ''
        },
        isDark: false
    },

    Lavender: {
        colors: {
            background: 'bg-gradient-to-br from-[#d3d3ff] to-[#D3E9FF]',
            text: 'text-[#09093380]', //header description
        },
        fonts: {
            main: 'font-nunito',
            heading: 'font-nunito text-[#090933]',
            priColor: 'text-[#090933]',
            secColor: 'text-[#090933]', //item name and description with 80% opacity
        },
        layout: {
            header: 'shadow-md p-4 text-red-500',
            footer: 'bg-[#d3E9FF] text-[#090933]',
            itemCard: 'bg-[#FFFFE3] font-[#09093370] shadow-inner rounded-xl p-2',
            itemPrice: 'text-[#090933] flex justify-end text-sm font-bold',
            cartadd: 'bg-[#09093370] text-[#FFFFE3] rounded-lg',
            cart: 'bg-[#09093370] text-[#FFFFE3] rounded-lg',
            categoriesOverview: 'bg-[#FFFFE3]',
            categoriesOverviewActive: 'bg-[#09093370] text-[#FFFFE3]',
            categoriesOverviewButtons: 'text-[#090933] bg-transparent whitespace-nowrap px-3 py-1 text-sm rounded-md transition-colors duration-200',
            images: 'rounded-md',
            grid: 'grid grid-cols-2 gap-4',
            list: 'space-y-4',
            detailpage: ""
        },
        detailPage: {
            main: "bg-gradient-to-br from-[#d3d3ff] to-[#D3E9FF] text-[#090933]",
            descripButton: "bg-[#090933] text-[#d3d3ff]",
            description: "text-[#09093390]",
            closeButton: "text-[#090933]",
        },
        watermark: {
            color: ''
        },
        isDark: false
    },

    Forest: {
        colors: {
            background: 'bg-gradient-to-br from-[#2e6f40] via-green-900 to-[#2e6f40]',
            text: 'text-green-200',
        },
        fonts: {
            main: 'font-sans',
            heading: 'font-serif text-green-100',
            priColor: 'text-green-50',
            secColor: 'text-green-50',
        },

        layout: {
            header: '',
            footer: 'bg-green-900 text-green-50',
            itemCard: 'bg-green-950 rounded-xl shadow-md m-1 p-2',
            itemPrice: 'text-green-50 text-sm font-bold ',
            cartadd: 'bg-green-600 rounded-lg text-green-50',
            cart: 'bg-green-600 rounded-lg text-green-50',
            categoriesOverview: 'bg-green-950 text-green-50',
            categoriesOverviewActive: 'bg-green-600',
            categoriesOverviewButtons: 'bg-transparent whitespace-nowrap shadow-[inset_0_2px_4px_0px_rgba(0,0,0,0.5)] px-3 py-1 text-sm rounded-md transition-colors duration-200',
            images: 'rounded-xl',
            grid: 'grid grid-cols-2 gap-3',
            list: 'space-y-4',
            detailpage: "bg-gradient-to-br from-green-800 via-green-950 to-green-950 text-green-100"
        },
        detailPage: {
            main: "bg-gradient-to-br from-green-800 via-green-950 to-green-950 text-green-100",
            descripButton: "bg-green-100 text-green-900",
            description: "text-green-100 opacity-70",
            closeButton: "text-green-100",
        },
        watermark: {
            color: 'brightness-0 invert'
        },
        isDark: true
    },
    Taupe: {
        colors: {
            background: 'bg-gradient-to-tl from-[#54463a] to-[#826d5a]',
            text: 'text-[#F7E6CA] opacity-80',
        },
        fonts: {
            main: 'font-sans',
            heading: 'font-serif text-[#F7E6CA]',
            priColor: 'text-[#F7E6CA]',
            secColor: 'text-[#54463a]',
        },
        layout: {
            header: 'bg-gradient-to-b from-[#54463a] to-transparent',
            footer: 'bg-[#54463a] text-[#F7E6CA]',
            itemCard: 'shadow-inner bg-[#F7E6CA] p-2 rounded-xl',
            itemPrice: 'text-sm font-bold text-[#54463a]',
            cartadd: 'bg-[#826d5a] text-[#F7E6CA] rounded-lg',
            cart: 'text-[#826d5a] bg-[#F7E6CA] rounded-lg',
            categoriesOverview: 'bg-[#826d5a] text-[#F7E6CA]',
            categoriesOverviewActive: 'text-[#826d5a] bg-[#F7E6CA]',
            categoriesOverviewButtons: 'bg-transparent whitespace-nowrap px-3 py-1 text-sm rounded-md transition-colors duration-200',
            images: 'rounded-md',
            grid: 'grid grid-cols-2 gap-2',
            list: 'space-y-4',
            detailpage: "bg-white"
        },
        detailPage: {
            main: "bg-gradient-to-tl from-[#54463a] to-[#826d5a] text-[#F7E6CA]",
            descripButton: "bg-[#F7E6CA] text-[#54463a]",
            description: "text-[#F7E6CA] opacity-70",
            closeButton: "text-[#F7E6CA]",
        },
        watermark: {
            color: 'brightness-0 invert'
        },
        isDark: true
    },
};

export default themes;


