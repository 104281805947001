import { gql } from '@apollo/client';


// Define the fragment for cache operations
export const CATALOG_FIELDS = gql`
  fragment CatalogFields on Catalog {
    id
    name
    description
    logo
    phone
    socialMedia {
      facebook
      instagram
    }
    theme
    viewMode
    allowCheckout
    businessType
    businessCategory
    location
    setupCompleted
    festiveMode
  }
`;

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      refreshToken
      user {
        id
        name
        email
      }
    }
  }
  `;

export const REGISTER_USER = gql`
  mutation RegisterUser(
    $name: String!, 
    $email: String!, 
    $password: String!, 
    $businessName: String!,
    $catalogName: String!,
    $isSubscribed: Boolean!
  ) {
    register(
      name: $name, 
      email: $email, 
      password: $password, 
      businessName: $businessName, 
      catalogName: $catalogName,
      isSubscribed: $isSubscribed
    ) {
      accessToken
      refreshToken
      user {
        id
        name
        email
        isSubscribedToNewsletter
      }
      catalog {
        id
        name
        catalogName
      }
    }
  }
`;

export const GOOGLE_SIGN_IN = gql`
    mutation GoogleSignIn($token: String!, $email: String!, $name: String!) {
        googleSignIn(token: $token, email: $email, name: $name) {
        token
        user {
            id
            name
            email
        }
        }
    }
`;

export const UPDATE_TOUR_STATUS = gql`
  mutation UpdateTourStatus($input: UpdateTourStatusInput!) {
    updateTourStatus(input: $input) {
      overview
      catalog
      personalization
      statistics
      lastCompletedAt
    }
  }
`;

export const RESET_TOUR_STATUS = gql`
  mutation ResetTourStatus {
    resetTourStatus {
      overview
      catalog
      personalization
      statistics
      lastCompletedAt
    }
  }
`;


export const CREATE_CATALOG = gql`
    mutation CreateCatalog($name: String!, $catalogName: String!) {
        createCatalog(name: $name, catalogName: $catalogName) {
        id
        name
        catalogName
        user
        }
    }
`;


export const UPDATE_SELECTED_CATALOG = gql`
  mutation UpdateSelectedCatalog($userId: ID!, $catalogId: ID!) {
    updateSelectedCatalog(userId: $userId, catalogId: $catalogId)
  }
`;

export const CATALOG_PERFORMANCE = gql`
  query CatalogPerformance($catalogId: ID!, $days: Int!) {
    catalogPerformance(catalogId: $catalogId, days: $days) {
      totalCategories
      totalItems
      totalItemClicks
      performancePercentageChange
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      user {
        id
        name
        email
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($id: ID!, $name: String, $email: String) {
    updateUser(id: $id, name: $name, email: $email) {
      id
      name
      email
    }
  }
`;


export const DELETE_CATALOG_MUTATION = gql`
  mutation DeleteCatalog($id: ID!) {
    deleteCatalog(id: $id) {
      success
      message
      deletedCategories
      deletedItems
      deletedImages
    }
  }
`;

export const DELETE_ACCOUNT_MUTATION = gql`
  mutation DeleteAccount {
    deleteAccount {
      success
      message
      deletedCatalogs
      deletedCategories
      deletedItems
      deletedImages
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      success
      message
    }
  }
`;


export const CREATE_ITEM = gql`
  mutation CreateItem($name: String!, $description: String, $priceVariants: [PriceVariantInput], $catalog: ID!, $category: ID, $image: String, $isVisible: Boolean,) {
    createItem(name: $name, description: $description, priceVariants: $priceVariants, catalog: $catalog, category: $category, image: $image, isVisible: $isVisible,) {
      id
      name
      description
      priceVariants {
        variant
        price
      }
      category
      image
      isVisible
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($name: String!, $catalog: ID!, $isVisible: Boolean) {
    createCategory(name: $name, catalog: $catalog, isVisible: $isVisible) {
      id
      name
      isVisible
    }
  }
`;


export const UPDATE_ITEM = gql`
  mutation UpdateItem($id: ID!, $name: String, $description: String, $priceVariants: [PriceVariantInput], $category: ID, $image: String, $isVisible: Boolean) {
    updateItem(id: $id, name: $name, description: $description, priceVariants: $priceVariants, category: $category, image: $image, isVisible: $isVisible) {
      id
      name
      description
      priceVariants {
        variant
        price
      }
      category
      image
      isVisible
    }
  }
`;

export const DELETE_ITEM = gql`
  mutation DeleteItem($id: ID!) {
    deleteItem(id: $id)
  }
`;

export const UPDATE_CATEGORY = gql`
mutation UpdateCategory($id: ID!, $name: String, $isVisible: Boolean) {
  updateCategory(id: $id, name: $name, isVisible: $isVisible) {
    id
    name
    isVisible
  }
}
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategory(id: $id)
  }
`;


export const UPDATE_CATALOG = gql`
  mutation UpdateCatalog(
    $id: ID!
    $name: String
    $description: String
    $logo: String
    $phone: String
    $socialMedia: SocialMediaInput
    $theme: String
    $viewMode: String
    $allowCheckout: Boolean
    $businessType: BusinessType
    $businessCategory: String
    $location: String
    $festiveMode: Boolean
  ) {
    updateCatalog(
      id: $id
      name: $name
      description: $description
      logo: $logo
      phone: $phone
      socialMedia: $socialMedia
      theme: $theme
      viewMode: $viewMode
      allowCheckout: $allowCheckout
      businessType: $businessType
      businessCategory: $businessCategory
      location: $location
      festiveMode: $festiveMode
    ) {
      id
      name
      description
      logo
      phone
      socialMedia {
        facebook
        instagram
      }
      theme
      viewMode
      allowCheckout
      businessType
      businessCategory
      location
      setupCompleted
      festiveMode
      
    }
  }
`;


export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      success
      message
      # token  # Remove this in production
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $newPassword: String!) {
    resetPassword(token: $token, newPassword: $newPassword) {
      success
      message
    }
  }
`;

export const SET_NEW_PASSWORD = gql`
  mutation SetNewPassword($token: String!, $password: String!) {
    setNewPassword(token: $token, password: $password) {
      success
      message
    }
  }
`;

export const SEND_VERIFICATION_EMAIL = gql`
  mutation SendVerificationEmail($email: String!) {
    sendVerificationEmail(email: $email) {
      success
      message
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($token: String!) {
    verifyEmail(token: $token) {
      success
      message
    }
  }
`;

export const UPDATE_ONBOARDING_STATUS = gql`
  mutation UpdateOnboardingStatus($input: UpdateOnboardingStatusInput!) {
    updateOnboardingStatus(input: $input) {
      id
      onboardingStatus
      onboardingStep
    }
  }
`;

export const REORDER_CATALOG = gql`
  mutation ReorderCatalog($catalogId: ID!, $newOrder: [ReorderInput!]!) {
  reorderCatalog(catalogId: $catalogId, newOrder: $newOrder) {
    success
    message
    updatedOrder {
      categoriesWithItems {
        id
        name
        order
        items {
          id
          name
          order
        }
      }
      itemsWithoutCategory {
        id
        name
        order
      }
    }
  }
}
`;

export const SUBMIT_CONTACT_FORM = gql`
  mutation SubmitContactForm($name: String!, $email: String!, $message: String!) {
    submitContactForm(name: $name, email: $email, message: $message) {
      success
      message
    }
  }
`;

export const SUBMIT_FEEDBACK = gql`
  mutation SubmitFeedback($input: FeedbackInput!) {
    submitFeedback(input: $input) {
      id
      rating
      comment
      createdAt
      user {
        id
        name
      }
    }
  }
`;

export const SUBSCRIBE_TO_NEWSLETTER = gql`
    mutation SubscribeToNewsletter($email: String!) {
        subscribeToNewsletter(email: $email)
    }
`;

export const UNSUBSCRIBE_FROM_NEWSLETTER = gql`
  mutation UnsubscribeFromNewsletter($email: String!) {
    unsubscribeFromNewsletter(email: $email)
  }
`;
