import React, { useRef } from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaPhone, FaEnvelope, FaImage, FaWhatsapp, FaCartPlus } from 'react-icons/fa';
import { FaShoppingCart } from 'react-icons/fa';
import themes from './Themes';
import RamadanOverlay from './RamadanOverlay';

const ItemPlaceholder = ({ name }) => (
    <div className="bg-gray-100 rounded-lg flex items-center justify-center aspect-square">
        <div className="text-center">
            <FaImage className="mx-auto text-gray-400 mb-2" size={24} />
            <p className="text-xs text-gray-600 font-medium">{name}</p>
        </div>
    </div>
);


const CatalogPreview = ({ businessInfo, categories, uncategorizedItems, themeName, viewMode, onViewModeChange }) => {
    const theme = themes[themeName] || themes.Classic;
    const headerRef = useRef(null);
    const containerRef = useRef(null);

    const allCategories = [...categories, { id: 'uncategorized', name: 'Others', order: Infinity, items: uncategorizedItems }];
    const sortedCategories = allCategories
        .sort((a, b) => a.order - b.order)
        .filter((category) => category.items.some((item) => item));


    // console.log(uncategorizedItems.length);
    // console.log("Categories : ", categories.length);

    const renderItem = (item) => (
        <div
            key={item.id}
            className={`${theme.layout.itemCard} overflow-hidden ${viewMode === 'list' ? 'flex items-center' : ''}`}
        >
            {item.image && (
                <div className={viewMode === 'grid' ? 'aspect-square' : 'w-20 h-20 flex-shrink-0'}>
                    <img
                        src={item.image}
                        alt={item.name}
                        className={`${theme.layout.images} ${viewMode === 'grid' ? 'w-full h-full' : 'w-20 h-20'} object-cover`}
                    />
                </div>
            )}
            <div className={`${viewMode === 'list' ? 'flex-grow px-2' : 'pt-2'}`}>
                <h5 className={`${theme.fonts.secColor} text-sm font-semibold`}>{item.name}</h5>
                {item.description && (
                    <p className={`text-xs ${theme.fonts.secColor} opacity-65 my-1 line-clamp-2`}>
                        {item.description}
                    </p>
                )}
                <div className='flex justify-between items-center'>
                    <p className={theme.layout.itemPrice}>
                        {item.priceVariants && item.priceVariants.length > 0
                            ? `${item.priceVariants[0].price.toLocaleString('en-NG', { style: 'currency', currency: 'NGN', minimumFractionDigits: 0 })}`
                            : 'Price not available'}
                    </p>
                    {businessInfo.allowCheckout && (
                        <button
                            className={`${theme.layout.cartadd} hover:brightness-70 p-1 shadow-sm transition-transform duration-200 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-opacity-50`}
                        >
                            <FaCartPlus size={15} />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <div
            ref={containerRef}
            className={`lg:w-[390px] lg:h-[640px] min-h-[80%] relative overflow-y-auto border-8 border-black rounded-3xl ${theme.colors.background} sm:w-full sm:h-full`}
        >
            {businessInfo.festiveMode && <RamadanOverlay isDark={theme.isDark} />}
            <header ref={headerRef} className={theme.layout.header}>
                <div className="flex items-center">
                    <div className="flex items-center flex-col mx-auto w-full max-w-xl px-4">
                        {businessInfo.logo ? (
                            <>
                                <img
                                    src={businessInfo.logo}
                                    alt="Logo"
                                    className="w-20 h-20 rounded-full object-cover"
                                />
                                <h1 className={`text-2xl font-bold ${theme.fonts.heading} text-center break-words overflow-hidden max-w-full`}>
                                    {businessInfo.name}
                                </h1>
                            </>
                        ) : (
                            <h1 className={`text-4xl mt-4 font-bold ${theme.fonts.heading} text-center break-words overflow-hidden max-w-full`}>
                                {businessInfo.name}
                            </h1>
                        )}

                        <p className={`text-xs mt-1 mb-4 ${theme.colors.text} text-center break-words max-w-full`}>
                            {businessInfo.description}
                        </p>
                    </div>
                </div>
            </header>

            {/* Categories nav remains the same */}
            <nav className={`sticky top-0 ${theme.layout.categoriesOverview} bg-opacity-70 backdrop-filter backdrop-blur-lg shadow-md p-2 z-10 overflow-x-auto`}>
                <div className="flex space-x-2 pr-4 min-w-max">
                    {sortedCategories.map((category) => (
                        <button
                            key={category.id}
                            className={`${theme.layout.categoriesOverviewButtons} hover:bg-opacity-80`}
                        >
                            {category.name}
                        </button>
                    ))}
                </div>
            </nav>

            <main className={`mt-4 min-h-fit px-4 ${theme.colors.text} pb-20`}>
                {sortedCategories.map((category) => (
                    <div key={category.id} className="mb-8">
                        {sortedCategories.length > 1 && sortedCategories[0] !== "Others" && (
                            <h2 className={`text-xl font-bold mt-6 mb-4 ${theme.fonts.priColor}`}>
                                {category.name}
                            </h2>
                        )}
                        <div className={viewMode === 'grid' ? theme.layout.grid : theme.layout.list}>
                            {category.items.map(renderItem)}
                        </div>
                    </div>
                ))}

                {uncategorizedItems.length == 0 && sortedCategories.length == 0 && (
                    <div className="flex-grow flex-col flex items-center justify-center my-48">
                        <p className={`text-xl ${theme.fonts.priColor}`}>No items available at the moment.</p>
                        <p>Please add items in the catalog page</p>
                    </div>
                )}
            </main>

            {/* Cart UI positioned within the preview */}
            {businessInfo.allowCheckout && (
                <div className={`sticky lg:bottom-7 right-4 z-50 flex pr-5 justify-end`}>
                    <div className={`${theme.layout.cart} p-2 shadow-lg cursor-pointer hover:scale-105 transition-transform duration-200`}>
                        <FaShoppingCart size={24} />
                        <span className="absolute -top-2 right-3 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                            0
                        </span>
                    </div>
                </div>
            )}

            {/* Footer content */}
            {(businessInfo.phone || businessInfo.socialMedia?.facebook || businessInfo.socialMedia?.instagram) && (
                <div className={`sticky bottom-0 ${theme.layout.footer} bg-opacity-90 backdrop-filter mt-auto backdrop-blur-lg p-2 pt-4 h-14 border-t-gray-200 border-t-2 lg:-mt-20`}>
                    <div className="flex space-x-5 my-auto justify-center">
                        {businessInfo.phone && (
                            <>
                                <a href={`tel:${businessInfo.phone}`} className={`${theme.layout.footer} hover:opacity-70`}>
                                    <FaPhone size={20} />
                                </a>
                                <a href={`https://wa.me/${businessInfo.phone}`} className={`${theme.layout.footer} hover:opacity-70`}>
                                    <FaWhatsapp size={20} />
                                </a>
                            </>
                        )}
                        {businessInfo.socialMedia?.facebook && (
                            <a href={businessInfo.socialMedia.facebook} className={`${theme.layout.footer} hover:opacity-70`}>
                                <FaFacebook size={20} />
                            </a>
                        )}
                        {businessInfo.socialMedia?.instagram && (
                            <a href={businessInfo.socialMedia.instagram} className={`${theme.layout.footer} hover:opacity-70`}>
                                <FaInstagram size={20} />
                            </a>
                        )}
                    </div>
                </div>
            )}

            <footer className={theme.layout.footer}>
                <hr className='w-2/5 mx-auto' />
                <a href='https://www.pricilog.com'>
                    <div className="flex flex-row items-start justify-center p-2 mb-2">
                        <p className={`text-xs ${theme.layout.footer}`}>Powered by | </p>
                        <img
                            src='pricilogtext.png'
                            alt='Pricilog Wordmark'
                            className={`h-4 ml-1 mt-0.5 ${theme.watermark.color}`}
                        />
                    </div>
                </a>
            </footer>
        </div>
    );
};

export default CatalogPreview;